<template>
  <button
    :class="className"
    @click.stop="handleButtonClick"
    :disabled="disabled"
    :style="
      disabled
        ? 'color: #a2a2a2;border: 1px solid #b7b7b6;background: #d4cdc5;'
        : ''
    "
  >
    {{ btnTxt }}
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: "button"
    },
    btnTxt: {
      type: String,
      default: "Submit",
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleButtonClick() {
      this.$emit("buttonClick");
    }
  }
};
</script>
