<template>
  <div-wrapper
    id="global-scan"
    className="modal_pageview scan-modal slideup modelslideup"
  >
    <div-wrapper className="model-header">
      <h2>{{ $t("_scan") }}</h2>
    </div-wrapper>
    <div-wrapper className="modal-midbox">
      <div-wrapper className="order-idbox">
        <div-wrapper className="field-row" v-if="!isGlobalScanner">
          <label class="text-label">{{ $t("_orderID") }}</label>
          <Input
            iType="text"
            name="orderId"
            value=""
            className="input-style"
            :placeholder="$t('_enterOrderId')"
            @input="getOrderIdFromInput"
          />
        </div-wrapper>
      </div-wrapper>
      <div-wrapper className="scan_box">
        <div-wrapper className="scan-msg">
          <p>{{ $t("_scanOrder") }}</p>
        </div-wrapper>
        <div-wrapper className="glob-scanner">
          <v-quagga
            v-if="!bar_code"
            :multiple="false"
            :torch="false"
            :onDetected="getBarcode"
            :inputStream="{
              constraints: {
                width: 1280
                /* height: 480 */
              }
            }"
            :singleChannel="false"
            :readerTypes="[
              'code_128_reader',
              'code_39_reader',
              'code_39_vin_reader'
              /* 'ean_reader',
              'code_39_reader',
              'ean_8_reader',
              'code_39_vin_reader',
              'codabar_reader',
              'upc_reader',
              'upc_e_reader',
              'i2of5_reader' */
            ]"
          />
        </div-wrapper>

        <div-wrapper className="process-ftr" v-if="orderId">
          <Button
            className="button"
            :btnTxt="$t('_proceed')"
            @buttonClick="emitOrderId"
          />
        </div-wrapper>

        <div-wrapper className="scan-ftr" v-else>
          <Button
            className="button"
            @buttonClick="closeScanner"
            :btnTxt="$t('_scanIsOver')"
          />
        </div-wrapper>
      </div-wrapper>
    </div-wrapper>
  </div-wrapper>
</template>
<script>
import { getMediaPermission } from "../services/MediaPermission";
import DivWrapper from "@/components/form/DivWrapper";
import Input from "@/components/form/InputWrapper";
import Button from "../components/form/ButtonWrapper";
export default {
  components: {
    DivWrapper,
    Button,
    Input
  },

  props: ["isGlobalScanner"],

  data() {
    return {
      bar_code: false,
      orderId: ""
    };
  },

  methods: {
    getOrderIdFromInput(_event) {
      if (_event.target.value) {
        this[_event.target.name] = _event.target.value;
      } else {
        this.bar_code = false;
        this[_event.target.name] = "";
      }
    },

    emitOrderId() {
      this.$emit("orderId", this["orderId"].trim());
    },

    getBarcode(data) {
      this.bar_code = data.codeResult.code.trim();
      // try {
      //   JSBridgePlugin.vibrate([50, 50, 50], -1);
      // } catch (err) {
      //   console.log(this.$t("_pleaseTryAgain"));
      // } finally {
      //   this.$emit("orderId", this.bar_code);
      // }
    },

    closeScanner() {
      this.$emit("closeScanner");
    }
  },
  mounted() {
    getMediaPermission(this);
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_modal-box.scss";
</style>
