var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div-wrapper',{attrs:{"id":"global-scan","className":"modal_pageview scan-modal slideup modelslideup"}},[_c('div-wrapper',{attrs:{"className":"model-header"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("_scan")))])]),_c('div-wrapper',{attrs:{"className":"modal-midbox"}},[_c('div-wrapper',{attrs:{"className":"order-idbox"}},[(!_vm.isGlobalScanner)?_c('div-wrapper',{attrs:{"className":"field-row"}},[_c('label',{staticClass:"text-label"},[_vm._v(_vm._s(_vm.$t("_orderID")))]),_c('Input',{attrs:{"iType":"text","name":"orderId","value":"","className":"input-style","placeholder":_vm.$t('_enterOrderId')},on:{"input":_vm.getOrderIdFromInput}})],1):_vm._e()],1),_c('div-wrapper',{attrs:{"className":"scan_box"}},[_c('div-wrapper',{attrs:{"className":"scan-msg"}},[_c('p',[_vm._v(_vm._s(_vm.$t("_scanOrder")))])]),_c('div-wrapper',{attrs:{"className":"glob-scanner"}},[(!_vm.bar_code)?_c('v-quagga',{attrs:{"multiple":false,"torch":false,"onDetected":_vm.getBarcode,"inputStream":{
            constraints: {
              width: 1280
              /* height: 480 */
            }
          },"singleChannel":false,"readerTypes":[
            'code_128_reader',
            'code_39_reader',
            'code_39_vin_reader'
            /* 'ean_reader',
            'code_39_reader',
            'ean_8_reader',
            'code_39_vin_reader',
            'codabar_reader',
            'upc_reader',
            'upc_e_reader',
            'i2of5_reader' */
          ]}}):_vm._e()],1),(_vm.orderId)?_c('div-wrapper',{attrs:{"className":"process-ftr"}},[_c('Button',{attrs:{"className":"button","btnTxt":_vm.$t('_proceed')},on:{"buttonClick":_vm.emitOrderId}})],1):_c('div-wrapper',{attrs:{"className":"scan-ftr"}},[_c('Button',{attrs:{"className":"button","btnTxt":_vm.$t('_scanIsOver')},on:{"buttonClick":_vm.closeScanner}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }